@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
:root {
  --dynamic-height: 100px; /* Set an initial default height */
}
html{
  height: 100vh;
}
body {
  margin: 0;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfbfba3;
  height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}



a {
  text-decoration: none !important;

}

a:hover {
  text-decoration: none !important;
}

/* -----------========== Global CSS for all pages common ==========------------- */

/* -------------------=============== Fonts =============------------------- */
.f-18b {
  font-size: 10px ;
  font-weight: 600;
}

.f-18 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.f-18eb {
  font-size: 10px ;
  font-weight: 700;
}

.f-24b {
  font-size: 24px;
  font-weight: 600;
}

.f-24 {
  font-size: 24px;
  font-weight: 500;
}

.f-32b {
  font-size: 32px;
  font-weight: 600;
}

.f-32 {
  font-size: 32px;
  font-weight: 500;
}

.f-bold {
  font-weight: 500 !important;
}

.f-16b {
  font-size: 14px ;
  font-weight: 500;
}

.f-16 {
  font-size: 14px  !important;
}

.f-14 {
  font-size: 14px  !important;
  font-weight: 500 !important;
}


/* -------------------============================------------------- */

/*---------========== Button ===========-------------*/
.btn-primary {
  border-radius: 0px !important;
  background: #2f5cae !important;
  border: 1px Solid #2f5cae !important;
  font-size: 14px;
  color: #fff !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.btn-primary-outline{
  border: 1px solid #2f5cae !important;
  background-color: transparent !important;
  color: #2f5cae !important;
}

.btn-primary:hover {
  box-shadow: 0px 4px 35px 4px rgba(35, 65, 148, 0.16) !important;
}

.btn-pri-outline {
  border-radius: 0px !important;
  color: #fff !important;
  font-size: 10px  !important;
  font-weight: 500 !important;
  letter-spacing: 0.03rem !important;
  box-shadow: 0px !important;
}

.btn-secondary {
  background-color: #213F93 !important;
}

.btn-sec-outline {
  font-size: 10px  !important;
  font-weight: 500 !important;
  letter-spacing: 0.03rem !important;
  border-radius: 0px !important;
  background-color: #fff !important;
  border: 1px solid #213F93 !important;
  color: #213F93 !important;
}

.btn-sec-outline:hover {
  color: #fff !important;
  background-color: #213F93 !important;
}

.btn-cancel-outline {
  font-size: 10px  !important;
  font-weight: 500 !important;
  letter-spacing: 0.03rem !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  border: 1px solid #dc3545 !important;
  color: #dc3545 !important;
}

.btn-cancel-outline:hover {
  color: #fff !important;
  background-color: #dc3545 !important;
}

/* .btn-action::before {
  font: var(--fa-font-solid);
  content: "\f140";
  padding-left: 10px !important;
  padding-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
} */

.icon-1::before {
  font: var(--fa-font-solid);
  content: "\f0fe";
  padding-left: 10px !important;
  padding-right: 3px;
  margin-top: auto;
  margin-bottom: auto;
}

.icon-2::before {
  font: var(--fa-font-solid);
  content: "\f14a";
  padding-left: 10px !important;
  padding-right: 3px;
  margin-top: auto;
  margin-bottom: auto;
}

.btn-none {
  background: transparent !important;
  color: #1c1c1c !important;
  border: 1px solid transparent !important;
  font-weight: 700;
}

/* -------------------============================------------------- */

/*---------========== Form ===========-------------*/
.form-control {
  background-color: #f8f9fa !important;
  font-size: 14px ;
  margin-bottom: 20px;
  border-radius: 0px !important;
}


.custom-select {
  font-size: 14px  !important;
  margin-bottom: 15px;
  border-radius: 0px !important;
}

label {
  color: #2d2d49 !important;
}

.form-floating>label {
  top: -3px !important;
}

.form-check-input:focus {
  border-color: none !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.form-select {
  background-color: #f8f9fa !important;
}

.form-select:focus {
  border-color: none !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  border-radius: 0px !important;
  background: #Fff !important;
}

/* .css-13cymwt-control {
  background-color: #f8f9fa !important;
} */

/* -------------------============================------------------- */

/*---------========== Card ===========-------------*/
.card {
  background-color: #fff !important;
  border-radius: 0px !important;
}

.card-bdr {
  border: 1px solid #eff0f2 !important;
}

/* -------------------============================------------------- */

/*---------========== Table ===========-------------*/
td.border.p-2 {
  font-size: 14px  !important;
}

/* -------------------============================------------------- */

/*-------============= Data table ==========--------------*/
.data-table-extensions {
  display: none !important;
}

.rdt_TableCol {
  border: 1px solid #eff0f2 !important;
  border-bottom: 0px solid !important;
  background: #f4f4f4 !important;
  border-radius: 0px !important;
  font-size: 10px  !important;
  font-weight: 500 !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  min-width: 160px !important;
  width: auto !important;
}

.rdt_TableCol_Sortable {
  font-size: 14px ;
  justify-content: space-between !important;
}

.rdt_TableCell {
  font-size: 14px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  min-width: 160px !important;
  border: 1px solid #eff0f2 !important;
}

/* -------------------============================------------------- */

/*-------============= Nav Link ==========--------------*/
.nav-link {
  border: 1px solid #A6A6A6 !important;
  color: #1c1c1c !important;
  font-size: 14px  !important;
  font-weight: 600 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link.one {
  border-radius: 0rem !important;
  background: unset !important;
  border: 1px Solid #002645 !important;
  color: #fff !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border: 0px solid !important;
  background: unset !important;
  border-bottom: 3px Solid #002645 !important;
  border-radius: 0 !important;
  color: #002645 !important;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
  background-color: transparent !important;
  border: 0px solid !important;
}

.view {
  min-height: 52px !important;
}

/* -------------------============================------------------- */

/*---- Student.css-----*/
.f-18 {
  font-size: 10px ;
  font-weight: 500;
}

div#row-0 {
  cursor: pointer;
}



.cacanJ:hover {
  background-color: #fff !important;
}

.LYwHa {
  border-radius: 0px !important;
  border: 1px solid #eff0f2 !important;
}

/*-------============= Search Box ==========--------------*/
.has-search .form-control {
  padding-left: 3rem !important;
  border-radius: 0px !important;
  background: #F2F4F5 !important;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.5rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  padding-left: 10px;
}

.btn-3 {
  padding: 10px !important;
}

/*-------============= End ==========--------------*/

/*-----======== Header.css ===============----*/
.m-wid {
  max-width: 1750px;
}

.cursor-pointer {
  cursor: pointer;
}

img.logo2 {
  width: 80px !important;
}


.slide-in {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.slide-out {
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;
}

button#dropdown-menu-align-responsive-1 {
  background: none !important;
  border: 0px;

}

.btn:hover {
  background-color: none !important;
}

.dropdown-menu.show {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 0px !important;
  border: 0px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f3f3f3 !important;
}

/*-------========== Responsive ===========-----------*/


/*===--- 24-08 ---===*/

.f-14 {
  font-size: 10px ;
}

.profile-img { 
  border: 0px solid #e9ebed !important;
  border-radius: 50%;
}

a.nav-link {
  border: 0px solid !important;
  font-weight: 500 !important;
}

a.nav-link:hover {
  color: #1c1c1c !important;
}

.drp-clr {
  color: #7e7e7e !important;
}

.drp-clr:hover {
  color: #1c1c1c !important;
}

img.logo {
  width: 120px !important;
}

a#collasible-nav-dropdown::after {
  font: var(--fa-font-solid);
  content: "\f007";
  margin-right: 4px;
  font-size: 10px ;
}

.navbar-toggler {
  border: 0px !important;
}

.navbar-toggler-icon {
  background-image: none !important;
}

span.navbar-toggler-icon::after {
  content: var(--bs-navbar-toggler-icon-bg);
  ;
  margin-right: 4px;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
  justify-content: normal !important;
}

.custom-nav {
  display: block;
  padding: 10px 0px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin: 0px 32px !important;
  color: #7e7e7e;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

/*-----======== End ===============----*/

/*-----======== Add-Studdent.css ===============----*/
.bg-1 {
  background-color: #f4f4f4 !important;
}

/*-----======== End ===============----*/

/*-----======== Log-in.css ===============----*/
.form-control:focus {
  border-color: #dee2e6 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, .25) !important;
}

.user.form-floating>label::before {
  font: var(--fa-font-solid);
  content: "\f007";
  margin-right: 8px;
  font-size: 10px ;
}

.user.form-floating>.form-control-plaintext~label::before,
.user.form-floating>.form-control:focus~label::before,
.user.form-floating>.form-control:not(:placeholder-shown)~label::before,
.user.form-floating>.form-select~label::before {
  font: var(--fa-font-solid);
  content: "\f007";
  margin-right: 8px;
}

.pass.form-floating>label::before {
  font: var(--fa-font-solid);
  content: "\f023";
  margin-right: 8px;
}

.pass.form-floating>.form-control-plaintext~label::before,
.pass.form-floating>.form-control:focus~label::before,
.pass.form-floating>.form-control:not(:placeholder-shown)~label::before,
.pass.form-floating>.form-select~label::before {
  font: var(--fa-font-solid);
  content: "\f023";
  margin-right: 8px;
}

.f-18b {
  font-size: 10px ;
  font-weight: 500;
  margin-left: 10px;
}

/*-----======== End ===============----*/

/*-----======== Report.css ===============----*/
.report-nav-container {
  position: relative;
  /* margin: 0px 10px; */
  color: #1c1c1c !important;
  /* height: 80px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: left;
  cursor: pointer;
}

.active-nav-bar {
  position: absolute;
  top: 70px;
  left: 1px;
  border-radius: 0px 0px 0 0;
  background-color: #1c1c1c;
  width: 100%;
  height: 10px;
}

/*-----======== End ===============----*/

/*-----======== View Student.css ===============----*/

.bg-2 {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
}

.btn-primary:hover {
  box-shadow: unset !important;
}

button#dropdown-menu-align-responsive-1 {
  background: none !important;
  border: 0px !important;

}



.outside {
  background-color: rgba(0, 0, 0, 0) !important;
  width: fit-content !important;
}

.flex-g {
  flex-grow: 1 !important;
}

.bg-2bdr {
  background-color: #F1F1F1;
  border: 1px #A6A6A6 solid;
  border-radius: 0px;
}

.modal-body {
  background-color: #F4F4F4 !important;
}

.modal-footer {
  border-top: 0px #fff !important;
  background-color: #F4F4F4 !important;
}

a:hover {
  text-decoration: none !important;
}

a:focus {
  text-decoration: none !important;
}

.min-w {
  min-width: 102px !important
}

/*-----======== End ===============----*/

/*------========= Calendar =========------*/

.rmv-all>button {
  background-color: transparent !important;
  border: 0px #ffffffff !important;
  color: #1c1c1c !important;
}

.rmv-all>button:hover {
  box-shadow: 0px 0px 0px 0px transparent !important;
}

.rmv-all>button.dropdown-toggle::after {
  color: #1c1c1c !important;
}

.nav-link.active>.rmv-all>button.dropdown-toggle::after {
  color: #fff !important;
}

.fc .fc-col-header-cell-cushion {
  text-decoration: none !important;
}

.fc .fc-daygrid-day-number {
  text-decoration: none !important;
  color: #1c1c1c;
  font-size: 10px ;
}

.fc .fc-toolbar {
  flex-wrap: wrap;
}

.rmv-all>button#dropdown-item {
  font-size: 10px  !important;
  padding: 0px;
  font-weight: 500 !important;
}

.fc .fc-button {
  text-transform: capitalize;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border: 0px Solid transparent !important;
}

.rmv-all-2 {
  padding: 0px !important
}

.rmv-all.nav-link>.dropdown.btn-group>.dropdown-toggle::after {
  color: #1c1c1c !important;
}

.rmv-all.nav-link.active>.dropdown.btn-group>.btn {
  color: #fff !important;
}

.rmv-all.nav-link.active>.dropdown.btn-group>.dropdown-toggle::after {
  color: #fff !important;
}

/*-------========== Responsive ===========-----------*/

@media screen and (max-width: 993px) {
  .fc .fc-button-group {
    margin-top: 10px;
  }

  .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
    margin-top: 10px;
  }
}

@media screen and (max-width: 993px) {
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    justify-content: center;
  }
}

/*-- 2908--*/

.btn-none.dropdown-toggle:after {
  display: none !important;
}

.btn-none.icon-1.dropdown-toggle::before {
  content: url(./new.svg);
}

.border-none {
  border: 0 !important;
}

.icon-1 button#dropdown-item {
  background: none !important;
  color: #1c1c1c !important;
  border: 0 !important;
  padding-left: 5px;
}

.icon-2 button#dropdown-item {
  background: none !important;
  color: #1c1c1c !important;
  border: 0 !important;
  padding-left: 5px;
}

.text-float {
  position: absolute;
  bottom: 70px;
  left: 100px;
}

.ws-nowrap {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 20px;
}

.ws-nowrap label {
  font-size: 10px ;
  font-weight: 600;
}

/*-----======== End ===============----*/


/* Styles 31082023 */

.my-button {
  background-color: #ffffff !important;
  color: #002645 !important;
  font-size: 16px !important;
  padding: 6px !important;
  transition: all 0.9s ease;
  margin-left: 2px !important;
}


.bordered-dd {
  cursor: pointer;
  background: #f9f8f6;
  padding: 5px 10px;
  border-radius: 0px;
  transition: all 0.4s ease;
}


.rounded-10 {
  border-radius: 0px !important;
}

.rounded-15 {
  border-radius: 0px !important;
}

.rounded-20 {
  border-radius: 0px !important;
}

.header-sp-2 {
  margin-top: 10px ;
  transition: all 0.4s;
}



ul.header-sp-2 .dropdown {
  color: #5b5b5b;
}



.header-dd:hover {
  color: #002645;
}

li.MuiBreadcrumbs-separator {
  margin-left: 5px;
  margin-right: 5px;
}

.my-breadcrumbs ol li a {
  color: #5b5ba0 !important;
}

.my-breadcrumbs ol li p {
  color: #b5b5b5;
  font-size: 12px ;
}

.page-title {
  color: #5b5b80;
  margin-bottom: 0.7px;
  font-size: 18px ;
}



button {
  border-radius: 0px !important;
}

.primary-filled {
  background: #002645 !important;
  color: #fff;
  font-size: 12px !important;
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px !important;
}
.primary-outlined {
background-color: #277ca0 !important;
border-color: #277ca0 !important;
color: #ffffff !important;

  /* border: 1px solid #002645 !important;
  color: #002645 !important; */
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(149, 157, 165, 0.0) 0px 0px 0px !important;
  font-size: 12px !important;
  min-width: max-content !important;
  width: max-content !important;
  padding: 3px 10px !important;
}

.primary-gray {
  background: #A4A4A4 !important;
  color: #fff;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px !important;
}

.primary-gray-outline{
  border: 1px Solid #b1b1b1 !important;
  color: #1c1c1c !important;
  background-color: transparent !important;
  box-shadow: none !important;
}



.primary-filled:hover {
  background: #dd7f22;
  color: #fff;
}

.secondary-filled {
  background: #ffffff00 !important;
  color: #1c1c1c!important;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px !important;
}

.secondary-filled:hover {
  background: #ffffff;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.fluid-container {
  max-width: 1920px !important;
  padding-inline: 0 !important;
  position: relative !important;
}

#sidebar {
  width: 240px;
  background: #002645;
  min-height: 100vh;
  padding: 20px;
  padding-inline: 25px;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.page-content {
  height: calc(100vh - 80px);
  overflow: auto;
  width: calc(100% - 300px);
  background: #fbfbfba3;
  padding: 0px 25px 20px 25px;
  border-top-left-radius: 15px;
  margin-top: 62px;
}



.headLMOpen #navbar {
  left: 220px;
  width: calc(100% - 220px);
  padding-left: 40px;
  /* height: 76px; */
}

#navbar {
  left: 0;
  width: 100%;
  position: fixed; 
  top: 0;
  z-index: 9;
  padding-inline: 20px;
  padding-block: 10px;
  border-bottom: 1px solid #ddd;
  background: #fbfbfba3;
}

ul.menu-list li a {
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

li.menu-item .dropdown {
  padding: 0;
  color: #fff;
  font-size: 14px ;
  cursor: pointer;
}

.menu-dd {
  border: 0.3px solid #DCDCDC;
  cursor: pointer;
  padding: 10px;
  border-radius: 0px;
  transition: all 0.5s;
  background-color: #fff;
  color: #1c1c1c !important;
}


/* .main-content {
  display: flex;
} */

ul.menu-list li.menu-item a {
  color: #fff;
  display: block;
  width: 100%;
  font-size: 14px;
}

.menu-icon {
  font-size: 17px !important;
  margin-right: 8px;
  margin-top: -5px;
}

.site-identity {

  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;


}

ul.menu-list .active {
  background: #fdfdfd2e !important;
  border-radius: 0px;
  color: #fff !important;

}

ul.menu-list {
  margin-top: 10px;
  flex-grow: 1;
}

ul.menu-list li.menu-item a:hover {
  background: #fdfdfd1f;
  border-radius: 0px;
}

li.menu-item .dropdown:hover {
  background: #fdfdfd1f !important;
  border-radius: 0px;
}

.my-breadcrumbs {
  font-size: 10px ;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1633um2-MuiPaper-root {
  border-radius: 0px;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

.custom-drop {
  padding: 10px !important;
  border-radius: 15px;
  transition: all 0.5s;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #002645 !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #002645!important;

}

button#simple-tab-0,
button#simple-tab-1,
button#simple-tab-2,
button#simple-tab-3
 {
  text-transform: none;
  font-size: 14px ;
  margin-right: 0px !important;
  width: 24% !important;
  min-width: 0px !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a6a6a6;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar:horizontal{
  height: 8px;

}
::-webkit-scrollbar-thumb:horizontal{

      border-radius: 0px;
        
    }

.topbar-right {

  padding-inline: 25px;
  align-items: center;
}

.search-section form {
  box-shadow: none;
  max-height: 44px !important;
}

.notification {
  /* width: 48px;
  height: 48px; */
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

span.MuiBadge-badge.MuiBadge-dot.MuiBadge-anchorOriginTopRight.MuiBadge-anchorOriginTopRightRectangular.MuiBadge-overlapRectangular.MuiBadge-colorPrimary.css-vzhiio-MuiBadge-badge {
  background-color: #002645;
}

.section-heading h6 {
  font-size: 14px ;
  color: #5b5b80;
}

.css-1ytufz-MuiAvatarGroup-root .MuiAvatar-root {
  border: 2px solid #fff;
  box-sizing: content-box;
  background: #91bb70;
}


.list-avatars {
  margin-top: 20px;
}

.js-left {
  justify-content: left;
}

ul.meeting-list li {
  background:  #f4f4f4;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding: 10px;
  border-radius: 0px;
  /* cursor: pointer; */
  transition: all 0.2s linear;
}

ul.meeting-list li:hover {
  background:  #ececec;

}

ul.meeting-list li:last-child {
  border-bottom: 0;
  margin-bottom: 0px;

}

.text-primary {
  color: #002645!important;
  font-size: 14px ;
}

.my-card {
  border-radius: 4px !important;
  box-shadow: none !important;
  border: 1px solid rgba(12, 18, 28, 0.12) !important;
}

button.fc-prev-button,
button.fc-next-button {
  background: #f4f4f4 !important;
  border: 0 !important;
  color: #2d3e4f !important;
  margin-right: 8px !important;
}

.fc .fc-toolbar-title {
  font-size: 14px ;
  color: #5b5b7f;
}

button.fc-dayGridMonth-button.fc-button-active,
button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active,
button.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active {
  background: none !important;
  border: 0;
  border-radius: 0 !important;
  border-bottom: 4px solid #002645 !important;
  color: #002645 !important;
  font-weight: 500;
  font-size: 14px;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

button.fc-timeGridWeek-button.fc-button.fc-button-primary,
button.fc-dayGridMonth-button.fc-button.fc-button-primary,
button.fc-timeGridDay-button.fc-button.fc-button-primary {
  background: none;
  border: none;
  color: #666666;
  font-size: 14px;
}

a.fc-col-header-cell-cushion {
  color: #1c1c1c ;
  font-weight: 500;
}


h6.form-lable {
  font-size: 14px ;
  color: #666;
  font-weight: 400;
}



.btn-icon {
  font-size: 18px !important;
}

.welcome-text {
  color: #555555;
  margin-bottom: 0;
}



span.badge {
  Color: #002645;
  border-radius: 0px;
  align-items: center;
  justify-content: center;
  font-size: 18px !important;
  padding: 4px;
}

.notification svg {
  color: #002645;
  font-size: 24px;
}

.MuiBadge-colorPrimary {
  background: #002645!important;
  border: 2px solid #ffffff !important;
  right: 6px !important;
  margin-bottom: 20px;
  top: 7px !important;
}

/* .css-13cymwt-control {
  border-radius: 0px !important;
  margin-bottom: 20px;
  font-size: 14px;
  border: 0.7px solid #dee2e6 !important;
} */

:focus-visible {
  outline: unset !important;
}

/* .css-13cymwt-control:hover {
  border: 0 !important;
  border-color: none !important;
} */

.custom-drop.dropdown-menu.show {
  position: relative !important;
  box-shadow: none !important;
  padding: 0 !important;
  padding-left: 28px !important;
  transform: unset !important;
  margin-top: 0px;
  transition: all 0.5s ease;
  background: transparent;
}

.custom-drop.dropdown-menu.show a {
  font-size: 14px !important;
}

.menu-pd {
  padding: 12px 15px !important;
}

.menu-pd:hover {
  background: #fdfdfd1f !important;
  border-radius: 0px;
}

.login-wrapper {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
}

.bg-overlay::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient( to bottom, #d9d9d900, #f6911ecf);
  z-index: 1;
}

.login-image {
  height: 100vh;

  overflow: hidden;
  position: relative;
}

img.bg-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.login-right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-inline: 100px;
}

.essentials h3 {
  color: #3C3C3C;
}

.essentials p {
  font-size: 14px;
  color: #A7A7A7;
}

.login-forms input {
  background: #F2F4F5!important;
  border: none;
  padding-inline-start: 55px !important;
  padding-inline: 20px;
  padding-block: 25px;
  border-radius: 0px !important;
}

.form-icon{
  position: absolute;
  top: 10px;
  left: 18px;
  color: #A4A4A4;
}

.sign-in-admin {
  font-size: 10px ;
  color: #002645;
}

a.dropdown-item a {
  color: #555;
}

table.table thead tr th {
  font-size: 14px;
  font-weight: 500 !important;
}

p.form-check-error {
  position: absolute;
  font-size: 14px;
  color: #e9736b;
  bottom: -32px;
}
p.error {
  position: absolute;
  font-size: 14px;
  bottom: -16px;
  color: #e9736b;
}
span.error {
  font-size: 14px;
  margin-bottom:0 !important;
  color: #e9736b;
}

.para{
  opacity: 0.75;
  font-size: 14px ;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.margin-tp-cl{
  top: 70px;
  margin-left:15px;
}

.lo-error{
  position: absolute;
  bottom: -36px;
  font-size: 12px;
  color: #e9736b;
}


/*---- 14/09/2023  -----*/
/* .css-1nmdiq5-menu{
  z-index: 10 !important;
}
.css-t3ipsp-control{
  margin-bottom: 20px !important;
} */

.reports-list span {
  background: #658cc1;
  color: #ffff;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  font-size: 10px ;
}

.reports-list{
cursor: pointer;
}

.reports-list:hover {
    background: #002645 !important;
    transition: all 0.5s ease;
    color: #fff !important;
}

.reports-list:hover>p> span{
  background-color: #ffffff !important;
  color: #002645 ;
}

/* .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: calc(100vh - 235px) !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: rgb(243, 243, 243);
} */


/* 
.control_textarea {
height:100%;
} */

/* .fc-event {
  height: 150px;
} */
.fc-v-event .fc-event-main {
  font-size: 12px;
  border: 0px !important;
  padding: 0px !important;
}

/* .fc-timegrid-slots tr {
  height: 90px !important;
}


.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: calc(100vh - 235px) !important;
} */

.fc .fc-timegrid-col.fc-day-today {
  background-color: rgb(243, 243, 243);
}

/*---- 22/09/2023  -----*/

/* .fc_eventContent-cus
{
  padding: 5px;
  width: 100%;
} */
/* .fc_image-cus {
  border-radius: 25px;
  padding: 3px;
 width: 40px;
 height: 40px;
} */


.btnmr-2{
  right: 2px;
}

.logout-section button {
  font-size: 14px ;
}

.logout-section button svg {
  width: 16px !important;
}

.logout-section button {
  background: #002645;
  border-radius: 0px;
  color: #ededed;
  padding: 7px 15px;
}
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root:hover{
  background-color: #f4f4f4 !important;
}
.primary-danger {
  border: 1px solid #f42d2d !important;
  color: #f42d2d !important;
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px !important;
  font-size: 12px !important;  
  cursor: none !important;
}
.strike_fw_student{
  text-decoration: line-through;

}


/*---- 25/09/2023  -----*/





/* 
.fc-timegrid-event-harness {
  position: relative;
} 
a.fc-event.fc-event-start.fc-event-end.fc-event-future.fc-timegrid-event.fc-v-event
{
  display: flex;
  flex-direction: column;
  position: relative;
}
 */


 /* Define the default state of events */
.event {
  background-color: #007bff;
  color: white;
  padding: 5px;
  border-radius: 0px;
  transition: transform 0.3s ease;
}

/* Define the state when events are hovered */
/* .event.hovered {
  transform: translateX(10px); 
} */



/* .fc-timegrid-event-harness {
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
  height: 49px;
  width: 200px;
  margin: 5px 1% 5px 1%;
  float: left;
  position: absolute;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  text-align: center;
  overflow: hidden;
  transition: 1.5s;
}
.custom-td .fc-timegrid-event-harness:hover {
  height: 145px;
} */

/* .custom-td{
  height: 145px;

width: 400px;
} */


/* Adjustments for Sidebar */
.Sidebar {
  width: 280px;
}

.Sidebar.closed {
  width:  60px;

}
.MuiBreadcrumbs-ol a {
 font-size: 12px;

}
.MuiMenu-list li{
  font-size: 14px;
}

/*27-09-2023*/
.react-select-25-listbox li{
  font-size: 14px;

}


.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion, .fc .fc-button-primary:disabled, .fc .fc-timegrid-axis-cushion, .fc .fc-col-header-cell-cushion, button.fc-today-button.fc-button.fc-button-primary {
  font-size: 14px;
}

button.fc-next-button.fc-button.fc-button-primary, button.fc-prev-button.fc-button.fc-button-primary{
  font-size: 14px;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.css-piqts5-MuiInputBase-root {
  font-size: 14px;
}

.react-tel-input .form-control{
  font-size: 14px;
  width: 100%;
}

.form-check{
  font-size: 14px;
}

select,
option, input {
    font-size: 14px !important;
}

.form-label{
  font-size: 14px;
}

p{
  font-size: 14px;
}

h6{
  font-size: 14px;
}

.custom-d{
  display: block;
}

.headLMOpen .custom-d{
  display: none;
}

.dropdown-item{
  font-size: 14px;
}

small{
  font-size: 14px !important;
}

.modal-title{
  font-size: 14px;
}
/*27-09-23*/

.popover 
{
  min-width: 350px !important;
  min-height: 120px !important;
}

.popoverStyle_cls_blue .popover-header 
{
  background: #bce0e9 ;
  color: #1c1c1c !important;
  font-size: 12px;
  padding: 8px !important;
}
.popoverStyle_cls_regul .popover-header 
{
  background: #b7e9ba ;
  color: #1c1c1c !important;
  font-size: 12px;
  padding: 8px !important;
}
.popoverStyle_cls_oto .popover-header 
{
  background: #b7e9ba ;
  color: #1c1c1c !important;
  font-size: 12px;
  padding: 8px !important;
}
.popoverStyle_cls_mus .popover-header 
{
  background: #b7e9ba;
  color: #1c1c1c !important;
  font-size: 12px;
  padding: 8px !important;
}
.popoverStyle_cls_blue .popover-body
{
  background: #dbeef4 ;
  color: #1c1c1c;
  font-size: 12px;
  padding: 5px;
  min-height: inherit;
  border-radius: 0px 0px 8px 8px;


}
.popoverStyle_cls_regul .popover-body
{
  background: #e7f7e3 ;
  color: #1c1c1c;
  font-size: 12px;
  padding: 5px;
  min-height: inherit;
  border-radius: 0px 0px 8px 8px;


}
.popoverStyle_cls_oto .popover-body
{
  background: #e7f7e3 ;
  color: #1c1c1c !important;
  font-size: 12px;
  padding: 5px;
  min-height: inherit;
  border-radius: 0px 0px 8px 8px;
}
.popoverStyle_cls_mus .popover-body
{
  background: #e7f7e3 ;
  font-size: 12px;
  color: #1c1c1c;
  padding: 5px;
  border-radius: 0px 0px 8px 8px;
  min-height: inherit;
}
.tm-title{
  margin: 0;
}


/* .body-slot
{
  visibility: hidden;
} */
/* .fc-timegrid-event-harness
{

  height: 25px;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event
{
  width: fit-content;
  height: fit-content;
 
} */

/* .event-hovered .body-slot
{
  visibility: visible;
} */
/* td:has(> a.event-hovered)
{
  width: fit-content;
  height: fit-content;
} */

/* td:has(> a.event-hovered)
{
  width:  200px;
} */
 /* td a.event-hovered{
height: fit-content !important;
} */

.event-hoveredtop{
  z-index: 999 !important;
  right: 0 !important;
  left: 0 !important;
  } 

/* .custom-td{
width: 300px;
} */
.custom-td .title-slot
{
  height: fit-content;
} 

svg.filled-background-icon {
  background: #f8f9fa;
  border-radius: 0px;
  font-size: 14px;

}
svg.float-right {
  float: right;
}

.fc .fc-scroller-liquid-absolute
{
  /* right: -17px; */
  overflow: scroll;

}



/*29-09-23 - sanjay*/

.card_blue .title-slot{
  padding: 5px !important;
  background-color: #bce0e9;
  color: #1c1c1c !important;
  overflow: hidden;
}
.card_blue  .body-slot{
  padding: 5px;
  color: #1c1c1c;
  background-color: #dbeef4;
  border-color: #dbeef4 !important;
}
.card_regu .title-slot{
  padding: 5px !important;
  background-color: #b7e9ba;
  color: #1c1c1c !important;
  overflow: hidden;
}
.card_oto .title-slot{
  padding: 5px !important;
  background-color: #b7e9ba !important;
  color: #1c1c1c !important;
  overflow: hidden;
}

.card_mus .title-slot{
  padding: 5px !important;
  background-color: #b7e9ba;
  color: #1c1c1c !important;
}
.card_regu  .body-slot{
  padding: 5px;
  color: #1c1c1c;
  background-color: #e7f7e3;
  border-color: #e7f7e3 !important;
}
.card_oto  .body-slot{
  padding: 5px;
  color: #1c1c1c;
  background-color: #e7f7e3;
  border-color: #e7f7e3 !important;
}
.card_mus  .body-slot{
  padding: 5px;
  color: #1c1c1c;
  background-color: #e7f7e3;
  border-color: #e7f7e3 !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
  background-color: transparent !important;
  color: #1c1c1c !important;
}

label{
  font-size: 14px;
}
button, input, optgroup, select, textarea{
  margin-right: 4px !important;
}
.pad-12 > button{
  padding: 3px 0;
  min-width: 50px;
}
.my-button {
  margin-left: 0px;
}


/*03-10-2023*/
.fc-event{
  height: fit-content;
}
.search-section{
  border: 1px solid rgba(12, 18, 28, 0.12);
}
.MuiTab-textColorPrimary{
  color: #1c1c1c !important;
}
.pad-btn{
  padding: 10px 15px !important;
}
.rounded-15{
  border: 1px solid rgba(12, 18, 28, 0.12);
}
.rdt_TableRow{
  color: #1c1c1c !important;
}
.rdt_TableHeadRow{
  color: #1c1c1c !important;
}




/*--- Surya ---*/
.fc-theme-standard td, .fc-theme-standard th
{
  width: 100% !important;
}

.fc-timegrid-slots table{
  width: 100% !important;
}
.fc-direction-ltr .fc-timegrid-slot-label-frame{
  text-align:unset;
}

.fc-timegrid-event-harness > .fc-timegrid-event{
  width: 100%;
  /* height: fit-content; */
  height: 100% !important;
  position: relative !important;
  white-space: nowrap;
  border: 0px !important;
  background-color: transparent !important;
}
.fc-timegrid-event-harness {
  display: table-cell;
  right: 0% !important;
} 
.fc_eventContent-cus {
  height: 100% !important;
}
.fc-timegrid-slots tr{
  height: 60px;
  /* display: grid; */
  width: 0px;
}



/*--- 04/10/2023 ---*/
.css-19kzrtu{
  padding: 0px !important; 
}
.MuiDivider-inset{
  margin-left: 0px !important;
}
.MuiListItemAvatar-root{
  min-width: 28px !important;
}
.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding{
  padding: 2px 8px 8px !important;
}
.MuiTypography-body2{
  font-size: 12px !important;
}
.body-slot{
  /* height: 60px; */
  height: 77% !important;
  overflow: hidden;
}
.required-field::after{
  content: "*";
  color: red;
  /* float: inline-end; */
  margin-left: 4px;
}
.no-bdr{
  background-color: transparent !important;
  color: #1c1c1c !important;
  padding: 0px !important;
  border: 0px !important;
  font-size: 14px !important;
}

.custom-tooltip {
  --bs-tooltip-max-width: 592px !important;
  --bs-tooltip-width: auto !important;
  --bs-tooltip-min-width: 200px !important;
  font-size: 12px !important;
  --bs-tooltip-color: #1c1c1c !important;
  --bs-tooltip-bg: #f3f3f3e1 !important;
  display: flex !important;
  flex-wrap: wrap !important;
}
.modal-content{
  border-radius: 0px !important;
}

.custom-tabs label{
  padding: 12px;
}


/* 05-10-2023 */
.MuiPaper-elevation{
  z-index: 10 !important;
}
.cus-menu-btn{
  background: #2c3e50 !important;
  color: #ffffff !important;
}

/* 06-10-2023 */
.nav-link:focus{
  outline: none !important;
}
.mf-tab {
  padding: 0 !important;
}
button:focus{
  outline: none !important;
}


/* 07-10-2023 */

.calendar-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
  margin-top: 24px;
  margin-left: 24px;
}

.calendar-main{
  flex-shrink: 0;
  /* flex: 0 0 75%;
  max-width: 75%; */
  flex-grow: 1;
}
.calendar-aside{
  flex-shrink: 0;
  flex: 0 0 20%;
  max-width: 20%;
}

.primary-light{
  background-color: #18a1b8 !important;
  color: #fff !important;
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(149, 157, 165, 0.0) 0px 0px 0px !important;
  font-size: 12px !important;
  min-width: max-content !important;
  width: max-content !important;
  padding: 3px 10px !important;
}
/* 
.btn-red{
  border: 1px solid #f42d2d !important;
  color: #f42d2d !important;
}
.btn-green{
  border: 1px solid #28a744 !important;
  color: #28a744 !important;
} */

.icon-red{
  color: #f42d2d !important;
}


/* 

background-color: #32761f !important;
    font-size: 12px;
    color: #ffffff !important;
    border-color: #fddddd !important; */

.rightSidebar{
  transform: translateX(-247px);
  visibility: hidden;
}
/* .righttogglediv{
  position: absolute;
  right:0;
  top: 124px;
  z-index: 20;
} */

.righttogglediv{
  position: absolute;
  right:-30px;
  top: 0;
  z-index: 1;
}
.cus-shrink-btn{
  background-color: #76818c !important;
  color: #fff !important;
}

.fa-whatsapp{
  color: #fff;
  background: #198754;
  border-radius: 81px;
  width: max-content;
  padding: 2px;
}



/* 20-10-23 */

.popover-body  ul{
 list-style-type: none;
 padding: 0px !important;
}
.pxy-6{
  padding: 6px 2px 6px 8px !important;
}
.pxy-6:hover{
  padding: 6px 2px 6px 8px !important;
}
.f-16{
  font-size: 16px !important;
  font-weight: 500;
}
.h2{
  font-size: 18px !important;
  font-weight: 400;
}
.text-caps{
  text-transform: capitalize;
}
.heading-card-cus{
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  color: #016ec7 !important;
}
.prime-clr{
  color: #012645 !important;
}
.tm-title{
  font-size: 12px !important;
}

.card-cus-h{
    min-height: 318px;
}
.border-0-cus {
  border-style: hidden;
}
.name-clr{
  font-weight: 600 !important;
  color: #016ec7 !important;
}
.flex-card-cus{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.cus-name{
color: #1c1c1c !important;
font-weight: 600 ;
}
span.badge {
  Color: #fff;
  border-radius: 0px !important;
  font-size: 12px !important;
  padding: 4px 6px;
  font-weight: 500;
  border-radius: 4px;
}
.box-shadow-cus{
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
}
.popover-arrow::after{
  border-bottom-color: #b7e9ba !important;
}
.modal-header .btn-close{
  margin-left: 0px !important;
}
.f-12{
  font-size: 12px !important;
}
.f-11{
  font-size: 11px !important;
}
.cus-warning{
  color: #ffc107 !important;
  font-weight: 500 !important;
}
.cus-danger{
  color: #dc3545 !important;
  font-weight: 500 !important;
}
.cus-primary{
  color: #0d6efd !important;
  font-weight: 500 !important;
}

/* 26-10-23 */
/* .pswicon
{
  float: right;
  margin-top: -51px;
  border: unset;
  background: #fff;
  color: #d88620;
} */

.noteerror{
    
    font-size: 14px;
   color: #e9736b;
   margin: 0;
}



/* 28-10-23 */
.btn:hover{
  color: #fff !important;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  border: 1px solid transparent !important;
  color: #fff !important;
}


.form-control{
  min-height: 38px !important;
}
.filled-danger {
  background-color: #f42d2d !important;
  color: #fff !important;
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px !important;
  font-size: 12px !important;  
  min-width: max-content !important;
  width: max-content !important;
  padding: 4px 6px !important;
}
.power-cus {
  font-size: 10px !important;
  margin-right: -15px;
  margin-top: 12px;
}
button.secondary-filled.p-0.btn.btn-contained {
  color: #1c1c1c !important;
}
.primary-blue{
  background-color: #277ca0 !important;
  color: #ffffff !important;
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(149, 157, 165, 0.0) 0px 0px 0px !important;
  font-size: 12px !important;
  min-width: max-content !important;
  width: max-content !important;
  padding: 4px 6px !important;
}
button.me-2.btn-primary-outline.btn.btn-primary:hover {
  color: #2f5cae !important;
}
button.secondary-filled.btn.btn-contained:hover{
  color: #1c1c1c !important;
}

/* 30-10-2023 */
.cus-form{
  max-width: 560px !important;
}

.cus-badge-Success{
  background-color: #28a745 !important;
  font-weight: 500 !important;
}
.cus-badge-danger{
  background-color: #dc3545 !important;
  font-weight: 500 !important;
}
.no-bdr:hover{
  color: #1c1c1c !important;
}

.cus-login{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
}


/* kathir 01/11/23 */
.pswicon{
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  }
  
  .three-dot-menu {
    position: relative;
    display: inline-block;
    margin-bottom: auto;
  }
  
  .three-dot-icon {
    cursor: pointer;
    font-size: 18px;
    color: #333;
  }
  
  .dropdown-options {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 8px;
  }
  
  .dropdown-options div {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-options div:hover {
    background-color: #f0f0f0;
  }

  @media (min-width: 1200px) and (max-width: 1300px) {
    .fc-view-harness.fc-view-harness-active {
      height: 50vh !important;
    }
  }


  /*=== 09/11/2023 ===*/

  .fc-view-harness.fc-view-harness-active {
    height: 66vh !important;
  }
/*   
  @media (min-width: 1560px) and (max-width: 1800px) {
  .fc-view-harness.fc-view-harness-active {
    height: 64vh !important;
  }
    }
  
  @media (min-width: 1500px) and (max-width: 1560px) {
    .fc-view-harness.fc-view-harness-active {
      height: 58vh !important;
    }
  } 
  
  @media (min-width: 1200px) and (max-width: 1300px) {
    .fc-view-harness.fc-view-harness-active {
      height: 50vh !important;
    }
  }  */


/*=== 10/11/2023 ===*/
input.form-control:active, input.form-control:focus {
  border: 1px solid #3ba7ff !important;
}
button#pagination-next-page,button#pagination-last-page, button#pagination-first-page, button#pagination-last-page {
  padding: 0px !important;
  height: auto !important;
}
.fc-view-harness.fc-view-harness-active {
  max-height: calc(100vh - 324px) !important;
}
.card-cus-height {
  max-height: calc(100vh - 324px) !important;
}

.cus-notes-height{
  max-height: calc(100vh - 404px) !important;
  overflow: scroll;
}


/* ==14/11/2023== */
a.fc-event, a.fc-event:hover{
  width: 100% !important;
}
.list-cus-h{
  max-height: 100% !important;
}
.btn-primary-outline:hover{
  color: #002645!important;
}
.active-btn{
  border: 1px solid #5db85c !important;
  font-size: 14px !important;
  color: #5db85c !important;
  padding: 4px 6px !important;
  width: 80px;
  display: flex;
}
.deactive-btn{
  border: 1px solid #f42d2d !important;
  font-size: 14px !important;
  color: #f42d2d !important;
  padding: 4px 6px !important;
  width: 100px;
  display: flex;
}
.rdt_TableCol:last-child, .rdt_TableCell:last-child {
    min-width: 226px !important;
}




/* 17-Nov-2023 */

.cus-th{
  vertical-align: top;
  background: #f4f4f4!important;
  border: 1px solid #eff0f2!important;
  border-bottom: 0 solid!important;
  height: 84px !important;
}
.cus-mb-0>  .form-control{
  margin-bottom: 0px !important;
}
.cus-td{
  border: 1px solid #eff0f2!important;
  vertical-align: middle !important;
}
.cus-th:not(:first-child,:nth-child(5)), .cus-td:not(:first-child){
  min-width: 120px !important;
}
.cus-pag{
  row-gap: 8px;
  display: flex;
  flex-direction: column;;
}
.cus-nxt{
  border: 1px solid transparent !important;
  background-color: transparent !important;
  font-size: 20px;
}
.cus-divider{
  font-size: 24px !important;
}
.cus-sort{
  margin: 3px;
  padding: 0;
  font-size: 12px;
  color: #277a9f !important;
  /* background-color: #d1cfcf !important; */
  border-radius: 2px;
}
.cus-sort-th{
  display: flex;
}

/* 24-Nov-2023 */
.pswlgin{
  position: absolute;
  top: 43px;
  right: 0;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 7px 8px;
  /* background: #fff; */
}

.pswicon {
  position: absolute;
  top: 43px;
  right: 9px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 7px 8px;
  /* background: #f8f9fa; */
}
p.error {
  position: absolute;
  font-size: 14px;
  bottom: 0;
  color: #e9736b;
  margin-bottom: 0px !important;
}

/* 25-Nov-23 */
.cus-les-sec{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.cus-les-left,.cus-les-right{
  display: flex;
  gap: 8px;
  align-items: center;
}

/* .has-search .form-control{
  padding-left: 0px !important;
} */
.MuiTablePagination-toolbar p{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
/* styles.css */

.actions {
  color: var(--text-secondary);
}

.textPrimary {
  color: var(--text-primary);
}

.super-app-theme--header {
  background-color: #f4f4f4;
}

.MuiDataGrid-row {
  border: 1px solid #eff0f2;
}

.MuiDataGrid-cell:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}



/* 13-Dec-2023 */
.card_mus .title-slot{
  overflow: hidden;
}

.cus-prnt-tbl {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  min-width: 218px !important;
}

.autr-btn{
  text-align: center;
  min-width: 114px !important;
  padding: 4px 6px !important;
}

.bdr-btm{
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.cus-pyt-dtls{
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.cus-pyt-dtls>h6{
  width: 140px !important;
  margin-bottom: 0px !important;
}

.btn-sec-outline{
  font-size: 12px !important;
}

.cus-log-btm{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}

.cus-w-auto{
  width: fit-content;
}

.link-dec, .link-dec:hover{
  text-decoration: underline !important;
}

.primary-blue, .filled-danger{
  line-height: 20px !important;
  padding: 4px 8px !important;
}
.dropdown-item.active, .dropdown-item:active{
  color: #1c1c1c !important;
}
.no-design, .no-design.btn.show{
  background-color:  #fbfbfba3 !important;
  border:  0px !important;
  margin-right: 0px !important;
  padding: 0 !important;
}
.no-design{
  position: relative;
}
.no-design::after{
  display: none;
}
.fs-14{
  font-size: 14px !important;
}
.drop-pad{
  padding: 0.7rem !important;
  border: 1px solid #e1e1e1;
}
.drop-pad:hover{
  background-color: #fff !important;
}
.notification-card{
  border: 0px !important;
  padding-bottom: 0.7rem !important;
  border-bottom: 1.5px solid #d7d7d7 !important;
  margin-bottom: 6px;
}
.notification-card:last-child{
  border: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.notification-card > .card-body{
  padding: 0% !important;
}
.notification-content{
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.notification-icon1{
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 2.5;
  border-radius: 50%;
  background-color: #b4d6fa;
}
.notification-icon2{
  padding: 12px;
  border-radius: 50%;
  background-color: rgb(51, 160, 55);
}
.notification-icon1 > svg, .notification-icon2 > svg{
  color: #007bff !important;
  font-size: 18px !important;
}
.req-h{
  font-size: 16px;
  font-weight: 600;
}
.req-c{
  font-size: 14px;
}
.req-t {
  font-size: 12px;
  color: inherit!important;
  opacity: .6;
  font-weight: 500;
}
.notification-dt{
  display: flex;
  gap: 4px;
  align-items: center;
}
.cnt-div{
  font-size: 16px;
  font-weight: 600;
}
.notification-batch {
  position: absolute;
  width: 16px;
  font-size: 10px;
  height: 16px;
  text-align: center;
  background: #fb3030;
  color: #fff;
  top: -4px;
  right: -1px;
  border-radius: 50%;
}


/* Calendar Tabs overflow */
div#simple-tabpanel-0, div#simple-tabpanel-1, div#simple-tabpanel-2, div#simple-tabpannel-3 {
  max-height: calc(100vh - 272px) !important;
  overflow: auto;
}

.htc-400{
  height: 400px;
}
/* .notifiMain{
  height: 400px;
  width: 300px;
  right: 14px !important
}
     */
.powbyCss
{
  width: 112px;
  height: 65px;
}     

.my-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
}

.calendar-main {
  position: relative;
}

.headerlogin {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  background-color: #ffebcd;
  /* align-items: center; */
  border-radius: 5px;
  color: #000;
  /* display: flex; */
  font-size: 10px;
  font-weight: 400;
  /* justify-content: flex-start; */
  padding: 5px 20px;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #dee2e6 !important;
}


.userguide_video-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
}

.userguide_video-wrapper iframe {
  height: 100%;
  width: 100%;
}

.userguide_video-wrapper video {
  height: 100%;
  width: 100%;
}
.req-tcred{
  font-size: 10px;
  color: #424243!important;
}
.bgcredinfo{
  background-color: #dee2e6;
}

input.form-control, input.MuiInputBase-input{
  color: #808080;
}
.css-1tx315l fieldset.MuiOutlinedInput-notchedOutline{
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-color: #808080;
  border-radius: 0;
}

.css-feqhe6 label{
  top: 8px!important;
}

.css-1x5jdmq{
  height: 2.1em!important;
}
.css-1ald77x{
  left: -13px!important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input{
  padding-left:0px!important;
  padding-top: 9px!important;
  margin-left: -4px!important;
}


.css-18nc3u2 .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.css-1bp1ao6 {
  background: #fff;
  min-height: 45px!important;
  height: 45px!important;

}


.fc-timegrid.blur-tbody table{
  filter: blur(5px);
}

.MuiTypography-body3{
  font-size: 10px !important;
}
.custom-note-text .MuiTypography-body1 {
  font-size: 12px !important;
}


.cus-prnt-tbl-parent {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  /* min-width: 218px !important; */
}
.calender-btn{
  color: #fff !important;
  font-weight: 400;
  text-transform: none !important;
  box-shadow: rgba(149, 157, 165, 0.0) 0px 0px 0px !important;
  font-size: 12px !important;
  min-width: max-content !important;
  width: max-content !important;
  padding: 3px 10px !important;
}

.calender-btn.re-enrol{
/* background-color: #607D8B !important; */
color: #100d0c !important;
}


.f-10.calender-badge {
  font-size: 10px  !important;
  font-weight: 300 !important;
}

.bg-success.calender-badge{
  background-color: var(--bs-teal) !important;
  color: black !important;
}

.hiddenInput {
  display: none !important;
}
















.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
  width: 100%;
  

  input.ReactTags__tagInputField {
    height: 38px;
    margin: 0;
    padding: 5px;
    font-size: 12px;
    min-height: 38px !important;
    /* border: 1px solid #eee; */
    width: 100%;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;

    &:focus {
      height: 38px;
      min-height: 38px !important;
      margin: 0;
      padding: 5px;
      font-size: 12px;
      /* border: 1px solid #eee; */
      width: 100%;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
    }
  }
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

.ReactTags__selected {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* padding: 5px; */
  margin-bottom: 10px;
  

  span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #002645;
    color: #fff;
    font-size: 12px;
    padding: 3px;
    margin: 5px;
    border-radius: 9px;
    display: flex;
    align-items: center;
  }

  .ReactTags__remove {
    color: #aaa;
    cursor: pointer;
    display: flex;
  }
}

.ReactTags__suggestions {
  position: absolute;

  ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
    padding: 5px 0;
  }

  li {
    padding: 5px 10px;
    margin: 0;
    text-align: left;

    mark {
      text-decoration: underline;
      background: none;
    }

    &.ReactTags__activeSuggestion {
      background: #4dabf7;
      color: #fff;
      cursor: pointer;

      span mark {
        color: #fff;
      }
    }
  }
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: #fff;
  margin-right: unset;
}

.ReactTags__error {
  color: #e03131;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    padding: 0 5px;
  }
}


.ht-line {
  border-bottom: 2px solid #c8cdd5;
  margin-bottom: 10px;
}
/* .ht-line:last-child {
  border-bottom: none; 
} */



.custom-option-align{
  padding: 5px;
  word-wrap: break-word;
  overflow: visible;
  white-space: normal;
  word-break: break-word;
}